import Layout from "@/layout";
import * as React from "react";
import { Services } from "@/components";
import SEO from "@/components/seo";
import { useLocation } from '@reach/router';

const Ser = () => {
  const {host} = useLocation();
  const canonical = `${host}/services`;
  return (
    <Layout>
      <SEO title="Services" canonical={canonical}/>
      <Services />
    </Layout>
  );
};

export default Ser;
